// TradingViewWidget.jsx
import React, { useEffect, memo, useState } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";

function TradingViewWidget() {
  const [symbols, setSymbols] = useState(["AMEX:SPY"]);
  const [index, setIndex] = useState(0);
  const [screenSize, setScreenSize] = useState(1);

  const addSymbol = (e) => {
    e.preventDefault();
    setSymbols([...symbols, "AMEX:SPY"]);
    setIndex((prev) => prev + 1);
  };

  useEffect(() => {
    const container = document.getElementById(
      `tradingview-widget-container-${index}`
    );
    console.log(index);
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
          "autosize": true,
          "symbol": "${symbols[index]}",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "allow_symbol_change": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;
    container.appendChild(script);
  }, [symbols, index, screenSize]);

  const handleScreenSize = (event) => {
    setScreenSize(event.target.value);
  };

  return (
    <>
      <div className="title">
        <div>Stock View (from Trading View) </div>
        <select value={parseInt(screenSize)} onChange={handleScreenSize}>
          <option value="1">1x1</option>
          <option value="2">2x2</option>
          <option value="3">3x3</option>
        </select>
      </div>

      <div className={`viewScreen `}>
        {symbols.map((symbol, index) => (
          <div
            className={`contain-item ${
              screenSize === "1"
                ? "item-1x1"
                : screenSize === "2"
                ? "item-2x2"
                : "item-3x3"
            }`}
            key={index}
          >
            <div
              id={`tradingview-widget-container-${index}`}
              className="tradingview-widget-container"
              style={{ height: "100%", width: "100%" }}
            >
              <div
                className="tradingview-widget-container__widget"
                style={{ height: "calc(100% - 32px)", width: "100%" }}
              ></div>
              <div className="tradingview-widget-copyright"></div>
            </div>
          </div>
        ))}
        <div className="contain-item" key={"add"}>
          <IoMdAddCircleOutline size="5em" color="white" onClick={addSymbol} />
        </div>
      </div>
    </>
  );
}

export default memo(TradingViewWidget);
